
export default {
  data() {
    return {}
  },
  methods: {
    logout() {
      this.$auth.logout();
    }
  }
}
