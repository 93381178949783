
import formularmodal from '~/mixins/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'maschinen-formular',
        event: 'formular::maschinen',
        titel: null,
        modelname: 'Maschine',
        model: 'maschinen',
        betrieb_id: null
      },
      model: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    fillModel(model = null) {
      if (!model) model = {};
      let empty = {
        name: '',
        kaufdatum: null,
        kaufpreis: null
      };
      if (this.params.betrieb_id) empty.betrieb_id = this.params.betrieb_id
      this.model = this.$fillMissing(model, empty);
    },
  },

}
