
import formularmodal from '~/mixins/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'anbaugeraete-formular',
        event: 'formular::anbaugeraete',
        titel: null,
        modelname: 'Anbaugerät',
        model: 'anbaugeraete',
      },
      model: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    fillModel(model = null) {
      if (!model) model = {};
      let empty = {
        name: '',
      };
      this.model = this.$fillMissing(model, empty);
    },
  },

}
