
import formularmodal from '~/mixins/formularmodal';
export default {
  mixins: [formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'treibstofftanks-formular',
        event: 'formular::treibstofftanks',
        titel: null,
        modelname: 'Treibstofftank',
        model: 'treibstofftanks'
      },
      model: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    fillModel(model = null) {
      if (!model) model = {};
      let empty = {
        name: '',
        strandort: ''
      };
      this.model = this.$fillMissing(model, empty);
    },
  },

}
