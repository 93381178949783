export default {
  data() {
    return {
      rollen: [],
      permissions: []
    }
  },
  methods: {
    rollenAbrufen() {
      this.$axios.get('/api/roles').then(response => {
        this.rollen = response.data.rollen;
        this.permissions = response.data.permissions;
      })
    },
  },
  mounted() {
    // this.rollenAbrufen();
  }
}
