
export default {
props: ['value'],
data() {
  return {
    text: this.value
  }
},
mounted() {
  this.$$abrufen('telefonbezeichnungen');
}
}
