export default {
  data() {
    return {
      params: null
    }

  },
  methods: {
    start(params) {
      this.params = params;
      this.fillModel(params.model);
      if (params.titel) this.modal.titel;
      this.$bvModal.show(this.modal.id);
    },
    hide() {
      this.$nuxt.$emit('refresh::'+this.modal.model);
      this.model = null;
    },
    speichern() {
      if (this.model.id) {
        this.$$update(this.modal.model, this.model, this.modal.modelname+' erfolgreich aktualisiert').then(response => this.$bvModal.hide(this.modal.id))
      }
      else {
        this.$$store(this.modal.model, this.model, this.modal.modelname+' erfolgreich angelegt').then(response => this.$bvModal.hide(this.modal.id))
      }
    }
  },
  mounted() {
    this.$nuxt.$on(this.modal.event, this.start);
  },
  beforeDestory() {
    this.$nuxt.$off(this.modal.event, this.start);
  },
}
