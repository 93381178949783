
/**
 * Importiert automatisch alle Modals aus dem Modals Ordner
 */
export default {
  mixins: [],
  props: [],
  data() {
    return {}
  },
  computed: {
    components () {
      // Lädt eine Liste mit allen .vue Files aus dem Ordner components/intern/modals
      const components = require.context('~/components/modals', false, /\.vue$/i)
      // Liefert die Namen der Components aufbereitet mit - statt Groß und Kleinschreibung, so dass die sowieso automatisch importierten Components richtig dargestellt werden können
      return components.keys().map(x => this.componentNameAufbereiten(x.split('/').pop().split('.')[0]))
    }
  },
  watch: {},
  methods: {
    componentNameAufbereiten(str) {
      return str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
    }
  },
  mounted() {},

}
