
import kontakt from '~/mixins/kontakt';
import formularmodal from '~/mixins/formularmodal';
export default {
  mixins: [kontakt, formularmodal],
  props: [],
  data() {
    return {
      modal: {
        id: 'personen-formular',
        event: 'formular::personen',
        titel: null,
        modelname: 'Person',
        model: 'personen',
      },
      model: null
    }
  },
  computed: {},
  watch: {},
  methods: {
    fillModel(model = null) {
      if (!model) model = {};
      let empty = {
        kontakt: this.$copy(this.emptyKontakt)
      };
      this.model = this.$fillMissing(model, empty);
    },

  },


}
