
import formularmodal from '~/mixins/formularmodal';
import permissions from '~/mixins/permissions';
export default {
  mixins: [formularmodal, permissions],
  props: [],
  data() {
    return {
      modal: {
        id: 'modalid',
        event: 'formular::personen-user',
        titel: null,
        modelname: 'User',
        model: 'users'
      },
      model: null,
      person: null,
    }
  },
  computed: {},
  watch: {},
  methods: {
    fillModel(model = null) {
      this.person = model;
      let user = {};
      if (model && model.user) user = model.user
      let empty = {
        permissions: [],
        roles: [],
      };
      user = this.$fillMissing(user, empty);
      user.roles = user.roles.map(rolle => {return rolle.id});
      user.permissions = user.permissions.map(permission => {return permission.id});
      this.model = user;
    },
    loginErstellen() {
      this.$$store('users', this.model, 'Login angelegt', '/api/personen/'+this.person.id+'/user').then(user => {
        this.model = user;
      })
    },
    loginMailSenden() {
      this.$$store('sendLoginMail', {email: this.model.email}, 'Login E-Mail versendet', '/api/users/'+this.model.id+'/sendLoginMail');
    },
    show() {
      if (this.$can('Nutzer verwalten')) this.rollenAbrufen();
    }
  },
  mounted() {

  }

}
