import Vue from 'vue';

let helpers = {
  computed: {
    $betrieb() {
      if (this.$model && this.$model.betrieb) return this.$model.betrieb;
      return null;
    },
  }
}

Vue.mixin(helpers);
export default helpers;
