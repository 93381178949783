
export default {
  mixins: [],
  props: {
    'value': null,
    displayFirma: {default: true},
    displayName: {default: true},
    displayStrasse: {default: true},
    displayOrt: {default: true},
    displayGeburtsdatum: {default: true},
    displayEmail: {default: true},
  },
  data() {
    return {
      kontakt: this.value,
    }
  },
  computed: {},
  watch: {
    value(neu) {
      this.kontakt = neu;
    }
  },
  methods: {
    edit() {
      this.$emit('input', this.kontakt);
    },
    addTelefon() {
      this.kontakt.telefonnummern.push({bezeichnung: '', nummer: '', type: null});
    }
  },
  mounted() {},

}
