
export default {
  mixins: [],
  props: [],
  data() {
    return {
      modal: {
        id: 'modalid',
        event: 'display-modal',
        titel: null,
        modelname: 'Model',
        model: 'models'
      }
    }
  },
  computed: {},
  watch: {},
  methods: {

  },

}
