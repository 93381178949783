
export default {
  data() {
    return {}
  },
  computed: {
    systemmenue() {
      if (this.$can('Nutzer verwalten') || this.$can('Betriebe verwalten') || this.$can('Treibstofftanks verwalten') || this.$can('Maschinen verwalten')) return true;
      return false;
    },
  },
  methods: {
    logout() {
      this.$auth.logout();
    }
  }
}
