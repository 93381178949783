export default {
  data() {
    return {
      emptyKontakt: {
        firma: null,
        geschlecht: null,
        vorname: null,
        nachname: null,
        strasse: null,
        plz: null,
        ort: null,
        geburtsdatum: null,
        email: null,
        telefonnummern: [],
      }
    }
  }
}
